<template>

    <span>

        <!--CaseNumberEdit-->
        <b-modal  title="Edit Case Number" class="modal-dark" v-model="caseNumberModal">
            <span class="d-flex align-items-center" style="margin-bottom: 10px">
                <p style="width: fit-content; margin: 0">New Case Number:&nbsp;</p>
                <b-form-input no-close-on-backdrop scrollable
                              class="modal-dark"
                              style="width: inherit; flex-grow: 1;"
                              v-model="selectedEvidence.caseNumber"
                              type="text" size="sm">
                </b-form-input>
            </span>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="caseNumberModal=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="updateEvidence">Submit</b-button>
            </template>
        </b-modal>

        <b-card header-tag="header" no-body>

            <!--Header-->
            <span slot="header">

                <span class="d-flex flex-row justify-content-between" style="align-items: center;">

                    <!--Title-->
                    <strong style="margin-right: 20px;">Evidence</strong>

                    <!--Search Filter-->
                    <b-input size="sm" v-model="searchText" type="text" placeholder="Search by Case Number" style="width: 200px;"/>

                </span>

            </span>

            <!--Table-->
            <table style="width: 100%; table-layout: auto;">
                <b-card class="d-flex flex-row align-items-center justify-content-between flex-wrap" style="background: #000000; font-size: 15px; margin: 6px" no-body>
                        <div class="flex-grow-1 d-flex flex-row m-0 align-items-center flex-wrap">
                          <b-col style="padding: 0 3px 0 3px;"><strong>Case Number</strong></b-col>
                          <b-col style="padding: 0 3px 0 3px; text-align: center"><strong>Number of Files</strong></b-col>
                          <b-col style="padding: 0 3px 0 3px; text-align: right"><strong>Download Case</strong></b-col>
                        </div>
                </b-card>
                <template v-for="(report, index) in displayedCases">

                    <b-card no-body :key="index" style="margin: 6px; background: #101010">

                        <!--Cases-->
                        <tr :key="index" style="height: 50px;">

                            <span class="d-flex flex-row justify-content-between" style="align-items: center; height: 100%;">

                                <!--Case Number/Toggle-->
                                <span style="width: 33.332%">
                                    <fa-icon v-if="rowToggleBooleans[index] === false" :icon="['fas', 'plus']"
                                             style="padding: 0 5px 0 10px; cursor: pointer;" @click="toggleRow(index)"/>
                                    <fa-icon v-else :icon="['fas', 'minus']"
                                             style="padding: 0 5px 0 10px; cursor: pointer;" @click="toggleRow(index)"/>&nbsp;
                                    <span style="font-size: 24px; vertical-align: middle;">
                                        <span style="color: #ffffffcc;">Case</span> {{report.caseNumber}}
                                    </span>
                                </span>

                                <!--Number of Files-->
                                <span style="width: 33.332%; text-align: center">
                                    {{report.evidencePieces.length}}
                                </span>

                                <!--Download Case-->
                                <span style="padding: 10px; width: 33.332%; text-align: right">
                                    <fa-icon :icon="['fas', 'download']" size="lg" style="cursor: pointer;" @click="downloadCaseFiles(report)"/>
                                </span>

                            </span>

                        </tr>

                        <!--Evidence Pieces for each case-->
                        <b-card v-if="rowToggleBooleans[index] === true"
                                style="background: #000000; font-size: 15px; margin: 0 16px 6px 16px" no-body>
                            <b-row style="margin: 0 3px 0 3px">
                                <b-col style="padding: 0"><strong>Case Type</strong></b-col>
                                <b-col style="padding: 0"><strong>Date/Time</strong></b-col>
                                <b-col style="padding: 0"><strong>Description</strong></b-col>
                                <b-col style="padding: 0"><strong>Address</strong></b-col>
                                <b-col style="padding: 0"><strong>Uploaded By</strong></b-col>
                                <b-col style="padding: 0"><strong>Case Sensitive</strong></b-col>
                                <b-col style="padding: 0" class="d-flex justify-content-between">
                                    <strong>Download Case</strong>
                                    <strong>Edit Evidence</strong>
                                </b-col>
                            </b-row>
                        </b-card>

                        <!--Evidence Pieces for each case-->
                        <template v-if="rowToggleBooleans[index] === true" v-for="(evidence, idx) in report.evidencePieces">
                            <b-card no-body :key="idx+'case'+index" style="margin: 0 16px 6px 16px; background-color: #070707">
                                <b-row style="padding: 0 19px 0 19px" class="d-flex align-items-center">
                                    <!--Case Type-->
                                    <b-col style="padding: 0; height: fit-content" :style="evidence.caseType ? '' : 'color: grey; font-style: italic'">
                                        {{evidence.caseType ? evidence.caseType : 'No Case Type'}}
                                    </b-col>

                                    <!--Date & Time-->
                                    <b-col style="padding: 0; height: fit-content">
                                        <span :style="evidence.date ? '' : 'color: grey; font-style: italic'">
                                            {{evidence.date ? formatDate(evidence.date) : 'No Date'}}
                                        </span>
                                        <span :style="evidence.time ? '' : 'color: grey; font-style: italic'">
                                            {{evidence.time ? evidence.time :  'No Time'}}
                                        </span>
                                    </b-col>

                                    <!--Description-->
                                    <b-col style="padding: 0; height: fit-content" :style="evidence.description ? '' : 'color: grey; font-style: italic'">
                                        {{evidence.description ? evidence.description : 'No Description'}}
                                    </b-col>

                                    <!--Location-->
                                    <b-col style="padding: 0; height: fit-content" :style="evidence.location ? '' : 'color: grey; font-style: italic'">
                                        <div v-if="evidence.location.address !== ''" class="d-flex flex-column">
                                            <div>{{evidence.location.address}}</div>
                                            <div>{{evidence.location.address2}}</div>
                                            <div>{{evidence.location.city}}, {{evidence.location.state}} {{evidence.location.zip}}</div>
                                        </div>
                                        <div v-else>
                                            No Address
                                        </div>
                                    </b-col>

                                    <!--Uploaded By-->
                                    <b-col style="padding: 0; height: fit-content" :style="evidence.uploadedBy ? '' : 'color: grey; font-style: italic'">
                                        {{evidence.uploadedBy ? evidence.uploadedBy : 'No Uploader'}}
                                    </b-col>

                                    <!--Case Sensitive-->
                                    <b-col style="padding: 0; height: fit-content">
                                        <b-form-select style="width: 108px"  size="sm" :options="caseSensitiveOptions" v-model="evidence.caseSensitive" @change="onCaseSensitiveChange($event, evidence.evidenceId)"/>
                                    </b-col>

                                    <!--Download & Edit-->
                                    <b-col style="padding: 0; height: fit-content" class="d-flex justify-content-between">
                                        <fa-icon :icon="['fas', 'download']" style="cursor: pointer;" @click="downloadEvidencePieceFiles(evidence.fileId, report, idx)"/>
                                        <fa-icon :icon="['fas', 'pencil']" style="cursor: pointer;" @click="showCaseNumberModal(evidence)"/>
                                    </b-col>

                                </b-row>
                            </b-card>
                        </template>

                    </b-card>

                </template>

            </table>

            <!--Navigation Buttons-->
            <nav style="text-align: center; margin-bottom: 6px">
                <ul class="pagination" style="display: inline-block; margin: 0;">
                    <li style="display: inline-block;">
                        <b-button type="button"
                                  @click="page = (pageRange() - 6 > 0 ? pageRange() - 6 : page)"
                                  style="display: inline-block; border-bottom-right-radius: 0; border-top-right-radius: 0; background: rgb(0,0,0); border: 1px solid #000000"
                                  :style="pageRange() - 6 > 0 ? '' : 'color: grey'">
                            <fa-icon :icon="['fas', 'angles-left']" size="lg" style="cursor: pointer;"/>
                        </b-button>
                    </li>
                    <li style="display: inline-block;">
                        <b-button type="button" @click="page - 1 > 0 ? page-- : ''" style="border-radius: 0; background: #090909; border: 1px solid #000000"
                                  :style="page - 1 > 0 ? '' : 'color: grey'">
                            <fa-icon :icon="['fas', 'angle-left']" size="lg" style="cursor: pointer;"/>
                        </b-button>
                    </li>
                    <li style="display: inline-block">
                        <b-button type="button" class="page-link" v-for="(pageNumber, ix) in pages.slice(pageRange() - 1 , pageRange() + 5)"
                                  :style="page === pageNumber ? 'color: grey' : ''"
                                  @click="page = pageNumber" :key="ix" style="display: inline-block; border-radius: 0"> {{pageNumber}}
                        </b-button>
                    </li>
                    <li style="display: inline-block;">
                        <b-button type="button" @click="page + 1 > pages.length ? '' : page++" style="border-radius: 0; background: #090909; border: 1px solid #000000"
                                  :style="page + 1 > pages.length ? 'color: grey' : ''">
                            <fa-icon :icon="['fas', 'angle-right']" size="lg" style="cursor: pointer;"/>
                        </b-button>
                    </li>
                    <li style="display: inline-block;">
                        <b-button type="button"
                                  @click="page = (pageRange() + 6 > pages.length) || !pages[pageRange() + 5] ? page : pageRange() + 6"
                                  style="display: inline-block; border-bottom-left-radius: 0; border-top-left-radius: 0; background: #000000; border: 1px solid #000000"
                                  :style="pageRange() + 6 > pages.length || !pages[pageRange() + 5] ? 'color: grey' : ''">
                            <fa-icon :icon="['fas', 'angles-right']" size="lg" style="cursor: pointer;"/>
                        </b-button>
                    </li>
                </ul>
            </nav>

        </b-card>

    </span>
</template>

<script>
import digitalevidence from '../../../services/digital-evidence';
import JsZip from 'jszip';

export default {
    name: "EvidenceView",

    created() {
        this.getAllEvidence();
    },

    computed: {
        displayedCases() {
            if (this.searchText !== '') {
                // filter cases based on search box
                let filteredEvidence = this.evidence.filter(item => {
                    return item.caseNumber.toLowerCase().includes(this.searchText.toLowerCase());
                });
                return this.paginate(filteredEvidence);
            } else {
                // if there is no search text, then all evidence is valid
                return this.paginate(this.evidence);
            }
        }
    },

    data() {
        return {
            caseSensitiveOptions: [
                { value: false, text: 'False' },
                { value: true, text: 'True' }
            ],
            evidence: [], // all evidence from database after being grouped based on case number
            evidenceLength: 0, // used for pagination
            page: 1, // current page for pagination
            pages: [], // used for navbar - choosing which page to navigate to
            perPage: 20, // how many cases displayed per page for pagination
            rowToggleBooleans: [], // booleans for if case is toggled open or not
            searchText: '', // text in input search box
            selectedEvidence: {},
            caseNumberModal: false
        }
    },

    methods: {
        showCaseNumberModal(evidence) {
            this.selectedEvidence = evidence;
            this.caseNumberModal = true;
        },
        updateEvidence() {
            digitalevidence.updateEvidence(this.selectedEvidence.evidenceId, this.selectedEvidence).then(() => {
                this.selectedEvidence = {};
                this.caseNumberModal = false;
                this.getAllEvidence();
            });
        },
        pageRange(page){
          let temp = (page ? page : this.page)
          if(temp <= 0 || (temp % 6 !== 0 && ((Math.floor(temp / 6) * 6) + 1) < 0)){
            return false;
          }else if(temp % 6 !== 0){
            return ((Math.floor(temp / 6) * 6) + 1);
          }else if(temp-5 < 0) {
            return false;
          }else{
            return (temp-5);
          }
        },
        downloadEvidencePieceFiles(fileId, report, index) {
            // downloads all the evidence from the fileId in a zip
            digitalevidence.downloadEvidence(fileId).then(res => {
                const fileName = 'Case-' + report.caseNumber  + '-Evidence-' + (index + 1) + '.zip';
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Error downloading requested evidence, please contact support!",
                    style: 'error'
                });
            });
        },
        downloadCaseFiles(report) {
            // creates and downloads a zip file
            // the zip file contains a zip file for each file ID entry associated with the case number
            // yes, there are nested zip files
            let evidenceCount = 0;
            let evidenceTotal = report.evidencePieces.length;
            let zip = new JsZip();

            for (let i = 0; i < report.evidencePieces.length; i++) {
                digitalevidence.downloadEvidence(report.evidencePieces[i].fileId).then(response => {
                    zip.file('Evidence-' + JSON.stringify(i + 1) + ".zip", response.data);
                    evidenceCount++;
                    if (evidenceCount === evidenceTotal) {
                        zip.generateAsync({
                            type: 'blob',
                            compression: 'DEFLATE'
                        }).then(res => {
                            const fileName = 'Case-' + report.caseNumber + '.zip';
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(res);
                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                        });
                    }
                });
            }
        },
        formatDate(date) {
            return date.substring(0, date.indexOf('T'));
        },
        getAllEvidence() {
            // gets all evidence for all the cases and sorts it by case number
            digitalevidence.getAllEvidence().then(response => {
                for (let i = 0; i < response.data.length; i++) {
                    const isUniqueCase = this.evidence.every(function (caseEvidence) {
                        return caseEvidence.caseNumber !== response.data[i].caseNumber;
                    });
                    if (isUniqueCase === true) {
                        this.evidence.push({
                            caseNumber: response.data[i].caseNumber,
                            evidencePieces: [{
                                caseSensitive: response.data[i].caseSensitive,
                                caseType: response.data[i].caseType,
                                date: response.data[i].evidenceDate,
                                description: response.data[i].description,
                                evidenceId: response.data[i]._id,
                                fileId: response.data[i].fileId,
                                location: response.data[i].location,
                                time: response.data[i].evidenceTime,
                                uploadedBy: response.data[i].uploadedBy
                            }]
                        });
                    } else {
                        this.evidence.forEach(caseNum => {
                            if (caseNum.caseNumber === response.data[i].caseNumber) {
                                caseNum.evidencePieces.push({
                                    caseSensitive: response.data[i].caseSensitive,
                                    caseType: response.data[i].caseType,
                                    date: response.data[i].evidenceDate,
                                    description: response.data[i].description,
                                    evidenceId: response.data[i]._id,
                                    fileId: response.data[i].fileId,
                                    location: response.data[i].location,
                                    time: response.data[i].evidenceTime,
                                    uploadedBy: response.data[i].uploadedBy
                                });
                            }
                        })
                    }
                }
            }).then(() => {
                this.evidenceLength = this.evidence.length;
                this.rowToggleBooleans = new Array(this.perPage).fill(false);
            });
        },
        onCaseSensitiveChange(boolean, id) {
            digitalevidence.changeCaseSensitive(id, boolean).then(response => {
                this.$mToast({
                    title: 'Success',
                    text: 'Case Sensitivity Successfully Changed',
                    style: 'success'
                });
            }).catch(() => {
                this.$mToast({
                    title: ' Error',
                    text: 'Error Changing Case Sensitivity',
                    style: 'error'
                });
            });
        },
        paginate(evidence) {
            // paginates the displayed evidence
            this.evidenceLength = evidence.length;

            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return evidence.slice(from, to);
        },
        setPages() {
            // populates array of all possible page numbers
            this.pages = [];
            let numberOfPages = Math.ceil(this.evidenceLength / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        toggleRow(caseIndex) {
            // toggles respective row to show or hide its evidence contents
            this.rowToggleBooleans[caseIndex] = !this.rowToggleBooleans[caseIndex];
            this.$forceUpdate();
        }
    },

    watch: {
        displayedCases() {
            // whenever displayed cases change, pagination pages do as well
            this.setPages()
        },
        searchText() {
            // whenever search input changes, rows are closed and page is set to 1
            this.page = 1;
            this.rowToggleBooleans.fill(false);
        }
    }
}
</script>